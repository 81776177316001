<template>
  <div
      class="pageContainer app-container studentContainer"
      style="position: relative"
  >
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>班级相册</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin-left: 30px; margin-block: 10px">
      <el-input
          v-model="formData.img_title"
          size="small"
          placeholder="文件名"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px"
      />

      <el-cascader v-model="formData.class_id"
                   :props="{
              label:'orgName',value:'orgId',emitPath:false}"
                   :options="classList" size="small"
                   @change="handleChange"
                   style="max-width: 200px; margin-right: 10px; margin-left: 10px;"
                   placeholder="班级名称"></el-cascader>
      <el-button
          size="small"
          icon="el-icon-search"
          @click="onSearch"
          plain
          type="primary"
      >查询
      </el-button
      >
      <el-button
          size="small"
          icon="el-icon-refresh"
          @click="onReset"
          type="warning"
      >重置
      </el-button
      >
      <el-button type="primary" size="small" @click="saveInfo()"
      >添加
      </el-button
      >
    </el-row>
    <el-table
        stripe
        :data="dataList"
        style="width: 100%; margin-bottom: 20px; color: #000"
        border
        size="mini"
    >
      <el-table-column
          prop="img_title"
          label="文件名"
          min-width="80"
      ></el-table-column>
      <el-table-column
          prop="class_name"
          label="所属班级"
          min-width="80"
      ></el-table-column>
      <el-table-column
          prop="cdate"
          label="创建时间"
          min-width="100"
      ></el-table-column>

      <el-table-column prop="img_url" label="图片" min-width="120">
        <template slot-scope="scope">
          <div style="width: 100px; line-height: 50px; overflow: hidden">
            <img :src="scope.row.img_url" style="width: 40px; height: 40px" v-viewer/>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-button
                type="text"
                size="small"
                @click="delectMessage(scope.row)"
                style="margin: 0 !important"
            >删除
            </el-button>
          </div>
          <div class="flexStart">
            <el-button
                type="text"
                size="small"
                @click="findOne(scope.row)"
                style="margin: 0 !important"
            >编辑
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑班级照片" :visible.sync="dialogVisible" width="600px">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item label="文件名" prop="img_title">
          <el-input v-model="ruleForm.img_title"></el-input>
        </el-form-item>
        <el-form-item label="所属班级" prop="class_name">


          <el-cascader ref="cascaderClass"
                       v-model="ruleForm.class_id"
                       :props="{
              label:'orgName',value:'orgId',emitPath:false}"
                       :options="classList"
                       @change="handleChange"
                       style="width: 100%"></el-cascader>
        </el-form-item>

        <el-form-item label="图片" prop="img_url">
          <el-row>
            <el-col :span="4"> &nbsp;</el-col>
            <el-col :span="20">
              <el-upload
                  action=""
                  :http-request="uploadOss"
                  :on-success="uploadCover"
                  :show-file-list="false"
                  name="img_url"
              >
                <div slot="tip" class="el-upload__tip">尺寸750:360</div>
                <img
                    v-if="ruleForm.img_url !== ''"
                    :src="ruleForm.img_url"
                    width="125"
                />
                <el-button v-else size="small" type="primary"
                >点击上传
                </el-button
                >
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
          >保存
          </el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total, prev, pager, next"
          :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      count: 0,
      ruleForm: {
        id: "",
        class_name: "",
        class_id: "",
        img_title: "",
        img_url: "",
        type: 1,
      },
      current_page: 1,
      per_page: 10,
      activeName: "td",
      dataList: [],
      formData: {},
      rules: {
        img_title: [
          {required: true, message: "请填写图片标题!", trigger: "blur"},
        ],
        img_url: [{required: true, message: "请选择图片", trigger: "change"}],
        class_id: [
          {required: true, message: "请选择班级", trigger: "change"},
        ],
      },
      classList: [],
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
    this.getClass()
  },
  methods: {
    getlist() {
      this.$http
          .post("api/sys_scenery_img_list", {
            page: this.current_page,
            pagesize: this.per_page,
            type: 1,
            img_title: this.formData.img_title,
            class_id: this.formData.class_id,
          })
          .then((res) => {
            this.dataList = res.data.data;
            this.count = res.data.page.count;
            this.current_page = res.data.page.current_page;
            this.per_page = res.data.page.per_page;
          });
    },
    getClass() {
      this.$http.post("api/sys_office", {"parent_id": "0", pmid: "0"}).then((res) => {
        this.deleteEmptyGroup(res.data[0].children).then(r => {
          this.classList = r
        })

      });
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    handleChange(e) {
      console.log(e)
      let name = this.$refs['cascaderClass'].getCheckedNodes()[0].pathLabels[2]
      this.ruleForm.class_name = name
    },

    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    delectMessage(e) {
      this.$http
          .post("api/sys_scenery_img_delete", {
            id: e.id,
          })
          .then((res) => {
            this.getlist();
          });
    },
    saveInfo(e) {
      this.ruleForm = {
        id: "",
        class_name: "",
        class_id: "",
        img_title: "",
        img_url: "",
        type: 1,
      };
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
              .post("api/sys_scenery_img_edit", {
                create_by: 1,
                id: this.ruleForm.id,
                img_title: this.ruleForm.img_title,
                class_name: this.ruleForm.class_name,
                class_id: this.ruleForm.class_id,
                img_url: this.ruleForm.img_url,
                type: 1,
              })
              .then((res) => {
                this.dialogVisible = false;
                this.getlist();
              });
        } else {
          console.log("保存失败!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    findOne(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.img_title = e.img_title;
      this.ruleForm.class_name = e.class_name;
      this.ruleForm.class_id = e.class_id;
      this.ruleForm.img_url = e.img_url;
      this.dialogVisible = true;
    },
    uploadCover(e) {
      this.ruleForm.img_url = e.src;
    },
  },
};
</script>


